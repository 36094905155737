import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

interface Props {
    title?: string
    subtitle?: string
    children?: any
}

export default function BasicCard({ title, subtitle, children }: Props) {
    return (
        <Card sx={{ margin: '3rem 0', padding: '3rem 1.5rem', textAlign: 'center' }}>
        <CardContent>
            {
                title &&
                <Typography variant="h5" component="div">{title}</Typography>
            }
            {
                subtitle &&
                <Typography sx={{ mb: 1.5 }} color="text.secondary">{subtitle}</Typography>
            }
            <Typography variant="body2">
                { children }
            </Typography>
        </CardContent>
        </Card>
    );
}
