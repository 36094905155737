import { useCallback, useEffect, useState } from 'react';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { login as authLogin } from 'modules/auth/login';
import { IUser } from 'interfaces/IUser';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from 'modules/firebase/auth';

interface IReturn {
    user?: IUser;
    isLoggingIn: boolean;
    error: string;
    clearError: () => void;
    login: (username: string, password: string) => void;
    logout: () => void;
}

interface IUserData {
    token: string;
    hasMembership: boolean;
}

export const useSession = (): IReturn => {
    const [ isLoggingIn, setIsLoggingIn ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>('');
    const { value: userData, setValue: setUserData, clearValue: clearUserData } = useSessionStorage<IUserData>('userData');
    const [ user, setUser ] = useState<IUser>();

    useEffect(() => {
        if (userData && !user) {
            setIsLoggingIn(true);
            signInWithCustomToken(auth, userData.token)
            .then(user => {
                setUser({
                    id: user.user.uid,
                    token: userData.token,
                    hasMembership: userData.hasMembership
                });
            })
            .finally(() => {
                setIsLoggingIn(false);
            });
        }
    }, [userData, user]);

    const clearError = useCallback(() => {
        setError('');
    }, []);

    const login = useCallback((username: string, password: string) => {
        if (isLoggingIn) {
            return;
        }

        if (!username || !password) {
            setError('Zadajte prihlasovacie meno a heslo');
            return;
        }

        let isActive = true;
        setIsLoggingIn(true);
        setError('');

        authLogin(username, password)
        .then(response => {            
            if (!response) {
                setError('Nesprávne prihlasovacie meno alebo heslo');
                return;
            }

            setUserData(response);
        }).catch(() => {
            setError(`Nesprávne prihlasovacie meno alebo heslo`);
        }).finally(() => {
            if (isActive) {
                setIsLoggingIn(false);
            }
        });

        return () => {
            isActive = false;
        };
    }, [isLoggingIn, setUserData]);

    const logout = useCallback(() => {
        clearUserData();
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }, [clearUserData]);

    return {
        user,
        isLoggingIn,
        error,
        clearError,
        login,
        logout
    };
};