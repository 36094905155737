import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useCallback, useState } from "react";

interface ISettings {
    message: string;
    severity: AlertColor;
}

interface IReturn {
    show: (settings: ISettings) => void;
    component: JSX.Element|null
}

export const useSnackbar = (onClose: () => void): IReturn => {
    const [ isOpen, setIsOpen ] = useState<boolean>(false);
    const [ settings, setSettings ] = useState<ISettings>();

    const show = useCallback((settings: ISettings) => {
        setSettings(settings);
        setIsOpen(true);
    }, []);

    const handleClose = () => {
        setIsOpen(false);
        setSettings(undefined);
        onClose();
    };

    const component = settings ? <Snackbar
        open={isOpen}
        onClose={handleClose}
    >
        <Alert
            onClose={handleClose}
            severity={settings.severity}
        >
            <strong>{settings.message}</strong>
        </Alert>
    </Snackbar> : null;

    return {
        show,
        component
    };
};