import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import '@videojs/http-streaming'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import { useEffect, useRef } from 'react'

interface Props {
    id: string
    thumbnail?: string
}

export default function Bunny ({ id, thumbnail = '' }: Props) {
    const playerRef = useRef(null)

    useEffect(() => {
        const player = videojs(playerRef?.current || '', {
            sources: [{
                src: `https://vz-aec43f46-358.b-cdn.net/${id}/playlist.m3u8`,
                type: 'application/x-mpegURL'
            }],
            controls: true
        })
        player.hlsQualitySelector();
    }, [ id ])
    return (
        <video
            ref={playerRef}
            className='video-js vjs-16-9 n vjs-big-play-centered'
            poster={`https://vz-aec43f46-358.b-cdn.net/${id}/thumbnail${thumbnail}.jpg`}
        ></video>
    )
}
