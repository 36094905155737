import { FC } from 'react';
import { Layout } from 'components/layout/Layout';
import { Form } from 'components/login/Form';
import { useSession } from 'hooks/useSession';
// import { useStream } from 'hooks/useStream';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { CONTENT_MARGIN } from 'constants/constants';
import StreamPage from 'components/stream/Page';
import { Box } from '@mui/system';

export const App: FC = () => {
    const { user, isLoggingIn, error, clearError, login, logout } = useSession();
    // const { html: streamHtml, isFetching: isFetchingStreamUrl } = useStream(user?.token)

    const renderBody = () => {
        if (isLoggingIn) {
            return <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'center', margin: CONTENT_MARGIN }}>
                    <CircularProgress size={50} />
                </Grid>
            </Grid>;
        }

        if (user) {
            return <Grid container>
                <Grid item xs={12} sx={{ margin: CONTENT_MARGIN }}>
                    {
                        user.hasMembership
                        ? <StreamPage />
                        : <Box sx={{ textAlign: 'center', marginTop: 5 }}>
                            <Typography variant="h6">
                                Nemáte zakúpenú online vstupenku. Zakúpiť môžete na:
                                &nbsp;
                                <a
                                    href="https://konferencia.godzone.sk"
                                    target="_blank"
                                    style={{ color: 'white' }}
                                    rel="noreferrer"
                                >https://konferencia.godzone.sk</a>
                            </Typography>
                        </Box>
                    }
                    {/* <div dangerouslySetInnerHTML={{
                        __html: streamHtml
                    }} /> */}
                </Grid>
            </Grid>;
        }

        return <Form
            login={login}
            error={error}
            clearError={clearError}
        />;
    };

    return <Layout
        user={user}
        logout={logout}
    >
        {renderBody()}
    </Layout>;
};
