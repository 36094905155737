import React from "react";
import ReactDOM from "react-dom";
import { App } from "components/App";
import 'modules/firebase/app';
import '@wordpress/block-library/build-style/style.css';
import 'styles/main.scss'

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
