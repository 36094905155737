import { useCallback, useEffect, useMemo, useState } from "react";


interface IReturn<T> {
    value?: T;
    setValue: (value: T) => void;
    clearValue: () => void;
}

const getRawValue = (key: string): string => window.sessionStorage.getItem(key) || '';

function serialize<T> (data: T): string {
    return JSON.stringify(data);
};

function unserialize<T> (data: string): T {
    return JSON.parse(data);
};

export function useSessionStorage<T> (key: string): IReturn<T> {
    const [ rawValue, setRawValue ] = useState<string>(getRawValue(key));

    const value = useMemo(() => {
        try {
            return unserialize<T>(rawValue);
        } catch (exception) {
            return undefined;
        }
    }, [rawValue]);

    const setValue = useCallback((value: T) => {
        setRawValue(serialize<T>(value));
    }, []);

    const clearValue = useCallback(() => {
        setRawValue('');
    }, []);

    useEffect(() => {
        if (!key) {
            return;
        }

        if (!rawValue) {
            window.sessionStorage.removeItem(key);
        } else {
            window.sessionStorage.setItem(key, rawValue);
        }
    }, [key, rawValue]);

    return {
        value,
        setValue,
        clearValue
    };
};