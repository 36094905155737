import { FC, useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useSnackbar } from "hooks/useSnackbar";
import { APPBAR_HEIGHT_PX, CONTENT_MARGIN } from "constants/constants";

interface IProps {
    login: (username: string, password: string) => void;
    error: string;
    clearError: () => void;
};

export const Form: FC<IProps> = ({ login, error, clearError }) => {
    const [ username, setUsername ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const { component: snackbar, show: showSnackbar } = useSnackbar(clearError);

    useEffect(() => {
        if (error) {
            showSnackbar({
                message: error,
                severity: 'error'
            });
        }
    }, [error, showSnackbar]);

    return <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
            minHeight: `calc(100vh - ${APPBAR_HEIGHT_PX}px)`
        }}
    >
        <Grid item xs={12} md={6} lg={4} sx={{ margin: CONTENT_MARGIN }}>
            {snackbar}
            <form onSubmit={event => {
                event.preventDefault();
                login(username, password);
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="h2" sx={{ textAlign: 'center' }}>
                            <strong>Prihláste sa</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            variant="outlined"
                            fullWidth
                            value={username}
                            placeholder="Prihlasovacie meno alebo email"
                            autoComplete="username"
                            onChange={event => {
                                setUsername(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="password"
                            variant="outlined"
                            fullWidth
                            value={password}
                            placeholder="Heslo"
                            autoComplete="current-password"
                            onChange={event => {
                                setPassword(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="outlined"
                            size="large"
                            fullWidth
                        >
                            Prihlásiť
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <a href="https://konferencia.godzone.sk/prihlasenie/?action=reset_pass" style={{ color: 'inherit' }}>
                            <Typography variant="body1" component="span">
                                Zabudnuté heslo
                            </Typography>
                        </a>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    </Grid>;
};
