import { FC } from "react";
import { AppBar, Button, CssBaseline, Grid, Toolbar, useMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { APPBAR_HEIGHT_PX } from "constants/constants";
import { IUser } from "interfaces/IUser";
import { Box } from "@mui/system";
import LogoutIcon from '@mui/icons-material/Logout';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#00ffc5'
        },
        background: {
            default: '#191C20'
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#08090b'
                }
            }
        }
    }
});

interface IProps {
    user?: IUser;
    logout: () => void;
}

export const Layout: FC<IProps> = ({ children, user, logout }) => {
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    return <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="static" sx={{ height: APPBAR_HEIGHT_PX }}>
            <Toolbar>
                <Grid container>
                    <Grid item xs={12}>
                        <Box component="div" sx={{ textAlign: 'center' }}>
                            <img
                                src="/logo.png"
                                alt="logo"
                                style={{ width: '145.5px', height: 'auto', marginTop: '30px' }}
                            />
                        </Box>
                        <Box component="div" sx={{ position: 'absolute', right: 15, top: APPBAR_HEIGHT_PX / 3 + 5 }}>
                            {user && (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        logout();
                                    }}
                                >
                                    {isSmUp ? <span>Odhlásiť</span> : <LogoutIcon />}
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        <Grid container>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    </ThemeProvider>
};
