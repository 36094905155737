import BasicCard from "./BasicCard"
import Bunny from "./Bunny"

interface ArchiveItem {
    id: string
    title: string
    thumbnail?: string
}


const ARCHIVE: ArchiveItem[] = [
    // {
    //     id: '1def43f0-6a9f-443f-b023-689cf991ef2a',
    //     title: 'BLOK 1 - Hlavu hore - myslite na to, čo je hore, Julo Slovák, Timothy, Q&A 01',
    //     thumbnail: '_1d2c8fe7'
    // },
    // {
    //     id: 'fc3dfcb9-9326-459d-9627-4d956007d1af',
    //     title: 'BLOK 2 - Talk - Hlava hore v každodennosti, Modlitebný večer'
    // },
    // {
    //     id: 'a12e005f-e193-4f36-8688-2dd71fd6de7e',
    //     title: 'BLOK 3 - Návrat k podstate uctievania, Marián Lipovský, Godzone Worship, Q&A 02',
    //     thumbnail: '_4013edcd'
    // },
    // {
    //     id: '6ce0105b-16ae-4fd3-b4a9-b7ea9543c162',
    //     title: 'BLOK 4 - Svätá omša, Jozef Mihok, Maranatha, Q&A 03',
    //     thumbnail: '_0fb55539'
    // },
    // {
    //     id: 'c68ee0b2-3a21-4624-8303-136e50e1e690',
    //     title: 'BLOK 5 - Mix Session',
    //     thumbnail: '_aa0f13b3'
    // },
    // {
    //     id: '27e5fcaa-175a-4f46-b417-4b13443d8947',
    //     title: 'BLOK 6 - Jednota a bratstvo, Janko Buc, ESPÉ'
    // }
]

// const LIBRARY_ID = 22012


function ArchiveListItem ({ id, title, thumbnail }: ArchiveItem) {

    return (
        <div className="item">
            {/* <div className="archive-video">
                <iframe
                    src={`https://iframe.mediadelivery.net/embed/${LIBRARY_ID}/${videoId}?autoplay=false&preload=false`}
                    loading='lazy'
                    allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
                    title={title}
                    allowFullScreen
                />
            </div> */}
            <Bunny id={id} thumbnail={thumbnail}/>
            <h3>{title}</h3>
        </div>
    )
}

export default function StreamArchive () {
    return (
        <div>
            {
                ARCHIVE.length ? <h2 className="title">Archív</h2> : null
            }
            {/* <BasicCard>Archív Godzone konferencie je dostupný do 15.05.2022.</BasicCard> */}
            <div className="archive-list">
                {
                    ARCHIVE?.map((video) => <ArchiveListItem {...video} key={video.id}/>)
                }
            </div>
        </div>
    )
}
