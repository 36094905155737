import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from 'modules/firebase/app';

interface IRequest {
    username: string;
    password: string;
}
interface IResponse {
    token: string;
    hasMembership: boolean;
}

export const login = async (username: string, password: string): Promise<IResponse|null> => {
    const functions = getFunctions(app);
    const loginCallable = httpsCallable<IRequest, IResponse>(functions, 'login');

    const response = await loginCallable({ username, password });
        
    if (!response.data) {
        return null;
    }

    return response.data;
};