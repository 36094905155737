import StreamArchive from "components/stream/Archive";
import StreamLive from "components/stream/Live";
import BasicCard from "./BasicCard";
import Grid from '@mui/material/Grid';

export default function StreamPage () {
    return (
        <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
            <BasicCard title="Archív GODZONE Konferencie 2022 bol ukončený">Tešíme sa na vás na <a href="http://worship.godzone.sk" target="_blank" rel="noreferrer" style={{ color: 'white' }}>WORSHIP NIGHTS 2022</a> v Prešove a Bratislave</BasicCard>
            {/* <StreamLive sourceUrl="https://videodelivery.net/cddc7285ca28ce6192cbfd7d6774938e/manifest/video.m3u8"/> */}
            {/* <BasicCard
                title="Livestream bude pokračovať o 15:55"
                subtitle="16:00 BLOK 06 - Jednota a bratstvo"
            /> */}
            {/* <BasicCard>V prípade problémov nám napíšte na <a href="mailto:konferencia@godzone.sk" style={{ color: 'white' }}>konferencia@godzone.sk</a><br/><br/><i>Ďakujeme :)</i></BasicCard> */}
            {/* <iframe style={{ minHeight: '600px', marginBottom: '3rem' }} title="Slido" src="https://app.sli.do/event/2KszwQ7iQzj3SeUrsNQgwj" width="100%" height="100%" frameBorder="0"></iframe> */}
            <StreamArchive/>
            {/* <Grid container spacing={2} sx={{ alignItems: 'flex-end', marginBottom: '3rem' }}>
                <Grid item xs={12} sm={6}>
                    <a href="https://godzoneshop.sk" target="_blank" rel="noreferrer">
                        <img className="img" src="/shop.png" alt="shop"/>
                    </a>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <a href="https://konferencia.godzone.sk/wp-content/uploads/2022/04/GDZN-MGZN-MINI-2022.pdf" target="_blank" rel="noreferrer">
                        <img className="img" src="/mgzn.png" alt="magazín"/>
                    </a>
                </Grid>
            </Grid> */}
        </div>
    )
}
